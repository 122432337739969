import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimSpaces]'
})
export class TrimSpacesDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur')
  onBlur() {
    let value: string = this.el.nativeElement.value;
    if (value) {
      value = value.replace(/\s+/g, ' ').trim();
      this.control.control?.setValue(value, { emitEvent: false });
    }
  }
}
