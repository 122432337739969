// src/app/services/google-maps-loader.service.ts
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsLoaderService {
  private scriptLoadingPromise: Promise<void>;
  private isScriptLoaded = false;

  constructor() {}

  load(): Promise<void> {
    if (this.isScriptLoaded) {
      return this.scriptLoadingPromise;
    }

    this.scriptLoadingPromise = new Promise<void>((resolve, reject) => {
      if (typeof google !== "undefined" && google.maps) {
        // Google Maps is already loaded
        this.isScriptLoaded = true;
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&v=3`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (typeof google !== "undefined" && google.maps) {
          this.isScriptLoaded = true;
          resolve();
        } else {
          console.warn("Google Maps API loaded but `google.maps` is undefined");
          reject(
            new Error("Google Maps API loaded but `google.maps` is undefined")
          );
        }
      };
      script.onerror = (error) => {
        console.warn(`Google Maps script error: ${error}`);
        reject(new Error(`Google Maps script error: ${error}`));
      };
      document.head.appendChild(script);
    });

    return this.scriptLoadingPromise;
  }
}
