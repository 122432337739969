import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AdminInterceptor implements HttpInterceptor {

  constructor(public router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let admin_token : any = sessionStorage.getItem('token');
    if (sessionStorage.getItem('token')) {
			request = this.addToken(request,admin_token);
    }
    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token : string ){       
    return request = request.clone({
      setHeaders: {  
        'Authorization': `Bearer `+ token
      }
    });

  }

  // private addToken(request: HttpRequest<any>, token : string ){       
  //   return request = request.clone({
  //     setHeaders: {  
  //       Authorization: `Bearer 1|Le5VNDSn9Fpr9iV1zl7lDrYRdSl0EyiK2PNgLl86`
        
  //     }
  //   });

  // }
}
