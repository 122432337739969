import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ResolveEnd, Router } from "@angular/router";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbTimeStruct,
  NgbCalendar,
  NgbDatepicker,
  NgbTimepicker,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { CommonService } from "../services/common.service";
import { GoogleMapsLoaderService }  from '../services/google-maps-loader.service'
import { Subject, Observable, timer } from "rxjs";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { AfterViewInit, ElementRef } from "@angular/core";
import {} from "googlemaps";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-vendorlocation",
  templateUrl: "./vendorlocation.component.html",
  styleUrls: ["./vendorlocation.component.css"],
})
export class VendorlocationComponent implements OnInit {
  center: google.maps.LatLngLiteral;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  start: number;
  end: number;
  loadTime: number;
  ds: google.maps.DirectionsService;
  dr: google.maps.DirectionsRenderer;
  directionsService: google.maps.DirectionsService;
  directionsDisplay: google.maps.DirectionsRenderer;

  mapElement: any;
  nextWebcam: any;
  task_id: any;
  loaderActive: boolean = false;
  costumer_location: any;
  jobidval: any;
  workshopLocation: any;
  get_task_id: any;
  vendor_assigned_status: any;
  latsource: any;
  submit: boolean;
  job_id: string;
  workshop_location: any;
  vendor_location: any;
  cust_location: any;
  workshop_status: any;
  vendorInfo: Object;
  vendor_status: any;
  latlong: any;
  reachtime: any;
  policyData: any;
  reachedStatus: any;
  reached_time: any;
  start_time: any;
  reachtime_result: any;
  options: { enableHighAccuracy: boolean; timeout: number; maximumAge: number };
  vendor_latlong_status: any;
  vendId: any;
  vendor_name: any;
  vendor_mob_no: any;
  showVendorForm: boolean = false;
  updateStatusButton: boolean = false;
  showReachStatusButton: boolean = false;
  vendor_submit_details: any;
  cust_name: any;
  cust_mob_no: any;
  cust_addr: any;
  cust_reg_no: any;
  reachStatus: any;

  showMap: boolean = true;

  updateVideoButton: boolean = false;
  droppVendorUploadForm: boolean = false;

  taskStatus: FormGroup;
  @ViewChild("mapContainer") gmap: ElementRef;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isTracking = false;
  map: google.maps.Map;
  lat1 = 19.1178548;
  lng2 = 72.8631304;

  // coordinates = new google.maps.LatLng(this.lat1, this.lng2);
  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   scrollwheel: true,
  //   disableDefaultUI: true,
  //   zoom: 15,
  // };

  title = "error";
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  directionList: boolean = true;
  public showWebcam = true;
  public allowCameraSwitch = true;
  //public switchCamera: Observable<boolean|string> = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  userDetailsForm: any;
  result: any;
  result_assign: any;
  errorMsg: any;
  successMsg: any;
  customer_location: any;
  vendorDetails: any;
  isImageTrue: boolean = false;
  vendor_info: boolean = false;
  showWebcamera: boolean = true;
  webcamImageEncoded: any;
  policy_details: any;
  display: any = "none";
  resultVendor: any;
  status_result: any;
  submitted: any;
  popupTitle: any;
  workshop_details: any;
  vendor_id: any;
  vendor_assign_id: any;
  vendor_details: any;
  customer_recent_no: any;
  idwatch: any;
  loadingactive: boolean = false;
  progress: any;
  updateReachStatusDisplay: any = "none";
  updateReachStatusForm: FormGroup;
  updateTaskStatusDisplay: any = "none";
  updateTaskStatusForm: FormGroup;
  vendorDetailsForm: FormGroup;
  uploadVideoTaskStatusForm: FormGroup;
  reach_time: any;
  uploadVideoStatusDisplay: any = "none";

  uploadDroppingVideoForm: FormGroup;
  uploadDroppVideoStatusDisplay: any = "none";

  currentDate = new Date();
  "MM/dd/yyyy h:mm:ss";
  taskId: any;
  scope: any;

  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  currentLat: any;
  currentLong: any;
  ids: any;
  row: any;
  showVendorAndCustomerDetails: boolean = false;
  isImageCaptured: boolean = false;
  get_id: any;
  pageExpire: boolean = false;
  customerLat: any;
  customerLong: any;
  intervalId: any;
  vendor_lat: any;
  vendor_longi: any;

  vendorDistance: any;
  vendorTravelTime: any;

  custShowroomDist: any;
  custtoShowroomTime: any;

  vendorShowroomDist: any;
  vendorShowroomTime: any;
  vendorDistanceStatus: boolean = false;
  meridian = true;
  details: any;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private datePipe: DatePipe,
    private googleMapsLoader: GoogleMapsLoaderService
  ) {
    let currentDate = this.datePipe.transform(new Date(), "MM/dd/yyyy h:mm:ss");
  }

  ngOnInit(): void {
    this.task_id = this.activatedRoute.snapshot.paramMap.get("id");
    // console.log(this.task_id);
    this.get_id = atob(this.task_id);
    // console.log(this.get_id);
    this.vendorDetailsForm = this.formBuilder.group({
      image: [""],
      vendor_lat: this.currentLat,
      vendor_long: this.currentLong,
      vendor_id: [""],
      vendor_name: ["", [Validators.required]],
      // vendor_number: [
      //   "",
      //   [
      //     Validators.required,
      //     Validators.minLength(10),
      //     Validators.maxLength(10),
      //     // Validators.pattern('^(?:(?:\+91)|(?:91))?[789]\d{9}$'),
      //     Validators.pattern(/^[6-9]\d{9}$/),
      //   ],
      // ],
    });
    this.updateReachStatusForm = this.formBuilder.group({
      task_id: [""],
      reach_time: ["", [Validators.required]],
      comments: ["", [Validators.required]],
    });
    this.updateTaskStatusForm = this.formBuilder.group({
      taskId: [""],
      comments: ["", [Validators.required]],
      status: ["", [Validators.required]],
    });

    this.uploadVideoTaskStatusForm = this.formBuilder.group({
      taskId: [""],
      vendorId: [""],
      uploadVideo: ["", [Validators.required]],
    });

    this.uploadDroppingVideoForm = this.formBuilder.group({
      taskId: [""],
      vendorId: [""],
      uploadDroopCustomerVideo: ["", [Validators.required]],
    });
    
    
    // if (google && google.maps) {
    //   // this.initAutocomplete();
    //   //console.warn("call");
    // } else {
    //   //console.warn("Google Maps API not loaded yet.");
    // }
    this.loadTime = Date.now();
    timer(150000).subscribe(() => {
      // Map has expired, handle it here
      this.pageExpire == true;
      console.log("Map expired");
    });
    console.log("ngOnInit: Starting to load Google Maps");
    this.googleMapsLoader
      .load()
      .then(() => {
        console.log("Google Maps loaded, initializing map");
        this.mapInitializer();
      })
      .catch((err) => {
        console.error("Google Maps failed to load", err);
      });
      
     this.getLocation();
    this.getWorkshopDetails();
     this.getpolicydata();
    // this.ds = new google.maps.DirectionsService();
    // this.dr = new google.maps.DirectionsRenderer();
    // this.getLocation();
    // this.intervalId = setInterval(() => {

    //   console.log("vendorlat", this.currentLat, "vendorlong", this.currentLong);
    // var j = 0;
    // this.watchId = navigator.geolocation.watchPosition(
    //   (position) => {
    //     // Handle the position update
    //     this.showTrackingPosition(position);
    //   },
    //   (err) => console.log(err),
    //   this.options
    // );

    // this.idwatch = navigator.geolocation.watchPosition(
    //   (position) => {
    //     // alert('in');
    //     this.showTrackingPosition(position);
    //     j++;
    //   },

    //   (err) => console.log(err),
    //   this.options
    // );
    // setTimeout(() => {
    //   // Clear the watch position
    //   navigator.geolocation.clearWatch(this.idwatch);
    //   console.log("himaa");
    // }, 7200000);
    // }, 600000);
    // this.options = {
    //   enableHighAccuracy: false,
    //   timeout: 10800000,
    //   maximumAge: 0,
    // };
    // setTimeout(() => {
    //   clearInterval(this.intervalId);
    //   console.log("done");
    //   // Clear the interval
    // }, 7200000);
  }

  ngAfterViewInit() {
    // Ensure map is initialized after view is fully loaded
    if (this.map) {
      this.setRoutePolyline();
    }
  }

  getpolicydata() {
    this.loaderActive = true;
    var get_id = atob(this.task_id);

    let SendData = {
      task_id: get_id,
    };
    this.commonService.getPolicyDataByTask(SendData).subscribe((response) => {
      this.result = response;
      // console.log(this.result);
      this.cust_name = this.result.customer_name;
      this.cust_mob_no = this.result.customer_mobile_no;
      this.cust_addr = this.result.customer_addr;
      this.cust_reg_no = this.result.customer_regitration_no;

      this.loaderActive = false;
      this.vendor_assign_id = this.result.vendor_id;

      if (this.result.status == true) {
        this.vendor_id = this.result.vendor_id;
        // console.log('Vendor Id',this.vendor_id);
        // this.reachedStatus = this.policyData.vendor_id;
        this.reached_time = this.result.reach_time;
        this.start_time = this.result.task_start_time;
        this.reachStatus = this.result.reach_status;
        this.customerLat = this.result.customer_lat;
        this.customerLong = this.result.customer_long;
        this.vendor_lat = this.result.vendor_lat;
        this.vendor_longi = this.result.vendor_longi;
        // console.log(this.reachStatus);

        this.vendor_mob_no = this.result.vendor_mobile_no;
        this.vendor_name = this.result.vendor_name;

        const vendor_distance = this.result.vendor_distance;
        // if (vendor_distance=="" || vendor_distance==null) {
        //     this.vendorDistanceStatus=true;
        // }

        if (this.result.status_id == "8") {
          this.updateStatusButton = false;
          this.updateVideoButton = false;
        }
        // if (this.result.status_id == "7" && this.result.upload_video_status == 1) { //comment video Upload Code
        //   this.showReachStatusButton = false;
        //   this.updateStatusButton = true;
        //   this.updateVideoButton = false;
        // }

        if (this.result.status_id == "7") {
          this.showReachStatusButton = false;
          this.updateStatusButton = true;
          this.updateVideoButton = false;
        }

        // if (this.result.status_id == "7" && this.result.upload_video_status == 0) {//comment video Upload Code
        //   this.showReachStatusButton = false;
        //   this.updateStatusButton = false;
        //   this.updateVideoButton = true;
        // }

        if (this.result.status_id <= "6") {
          this.showVendorForm = false;
          this.showReachStatusButton = true;
        }

        if (this.result.status_id < "5" || this.result.status_id == "14") {
          this.showVendorForm = true;
        }

        if (this.result.reach_status == "reached" && this.result.status_id != "14") {
          this.showVendorForm = false;
          this.showMap = false;
        }

        // if (this.result.status_id == "8" && this.result.upload_video_status != 2) {
        //   this.droppVendorUploadForm = true;
        // }

        if (this.showMap) {
          this.setRoutePolyline();
        }
        // let vendar_id_policy = this.vendor_id;
        // console.log("reachedtime", this.result.reach_time);
      } else {
        this.showVendorForm = true;
      }

      //this.getTemporaryTrack();
    });
  }

  // showTrackingPosition(position) {
  //   console.log(
  //     `tracking position:  ${position.coords.latitude} - ${position.coords.longitude}`
  //   );
  //   this.currentLat = position.coords.latitude;
  //   this.currentLong = position.coords.longitude;
  //   setTimeout(() => {
  //     clearInterval(this.intervalId);
  //     console.log("tracking done");
  //     // Clear the interval
  //   }, 7200000);
  // }
  closePopup1() {
    this.updateTaskStatusDisplay = "none";
  }
  closePopup() {
    this.updateReachStatusDisplay = "none";
  }
  openModel() {
    this.submit = true;
    this.popupTitle = "Update Location";
    this.updateTaskStatusDisplay = "block";
  }
  getLocation() {

  var get_id = atob(this.task_id);

    let SendData = {
      task_id: get_id,
    };
    this.commonService.getPolicyDataByTask(SendData).subscribe((response) => {
      this.result_assign = response;
      this.vendor_assign_id = this.result_assign.vendor_id;
      if (navigator.geolocation) {
        console.log('Yes Call It');
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // console.log('Rahul position.coords',position.coords);
            this.currentLat = position.coords.latitude;
            this.currentLong = position.coords.longitude;
            if (this.vendor_assign_id!="") {
              console.log('Yes Call It 1');
              this.getVendorIdByTaskId();
            }
          },
          (error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }


    });


  }

  getVendorIdByTaskId() {
    this.loaderActive = true;

    let payload = {
      task_id: this.get_id,
      clat: this.currentLat,
      clong: this.currentLong,
    };
    this.commonService
      .getVendorIdByTaskIdByJob(payload)
      .subscribe((response: any) => {
        this.loaderActive = false;
        this.vendor_id = response.result;
        // console.log(this.vendor_id);
      });
  }

  getWorkshopDetails() {
    var get_id = atob(this.task_id);
    // this.loaderActive = true;
    // console.log(get_id);
    let payload = {
      task_id: get_id,
      vendor_id: this.vendor_id,
    };

    this.commonService
      .getLatLongDetailsByWorkshop(payload)
      .subscribe((response: any) => {
        this.result = response;
        this.loaderActive = false;
        localStorage.setItem("result", JSON.stringify(this.result.result));

        if (this.result.workshop_status) {
          this.costumer_location = this.result.result.customer_location;
          this.jobidval = this.result.result.customer_location.jobid;
          this.vendorDetails = this.result.result.vendor_location;
          this.workshopLocation = this.result.result.workshop_location;
          this.task_id = this.get_task_id;
          //this.customer_name = this.customer_location.customer_name;
          this.vendor_assigned_status = this.result.vendor_asigned_status;
          if (this.vendor_assigned_status == true) {
            var waypoint = {
              latitude: this.currentLat,
              longitude: this.currentLong,
            };
            // console.log("custumer_location", waypoint);
            var origin = {
              latitude: this.vendorDetails[1]["lat"],
              longitude: this.vendorDetails[1]["longi"],
            };
            // console.log("vendorlocation", origin);
            var destination = {
              latitude: this.workshopLocation.lat,
              longitude: this.workshopLocation.longi,
            };
            // console.log("workshoplocation", destination);
          }
        }
        this.vendor_status = response.vendor_status;
        // console.log(this.vendor_status);

        let result = response.result;
        // console.log(result);

        this.vendor_details = result.vendor_details;
        this.workshop_details = result.workshop_location;
        // console.log("workshop",this.workshop_details);
        this.resultVendor = result.vendor_location;
        this.policy_details = result.customer_location;
      });
  }

  setRoutePolyline() {
    console.log("call api for");
    let latlongdata = localStorage.getItem("result");
    this.latlong = JSON.parse(latlongdata);
    console.log("latlong", this.latlong);

    let start = {
      lat: this.customerLat,
      lng: this.customerLong,
    };

    let end = {
      lat: this.latlong?.workshop_location.lat,
      lng: this.latlong?.workshop_location.longi,
    };
    // console.log(end);

    // let waypoint = {
    //   lat: this.latlong?.vendor_location[0].lat,
    //   long: this.latlong?.vendor_location[0].longi,
    // };
    let waypoint = {
      lat: this.vendor_lat,
      long: this.vendor_longi,
    };

    let origin_lat = Number(start.lat);
    let origin_lng = Number(start.lng);
    let origin = {
      lat: origin_lat,
      lng: origin_lng,
    };
    // console.log("customer", origin);

    let end_lat = Number(end.lat);
    let end_lng = Number(end.lng);
    let destination = { lat: end_lat, lng: end_lng };

    // console.log("workshop", destination);
    let waypoint_lat = Number(waypoint.lat);
    let waypoint_lng = Number(waypoint.long);
    let waypointt = { lat: this.currentLat, lng: this.currentLong };
    // let waypointt = { lat: waypoint_lat, lng: waypoint_lng };
    // console.log("vendor",waypointt);

    var markerStart = new google.maps.Marker({
      position: origin,
      title: "Customer Location",
      icon: {
        url: "./assets/front/img/customer.png",
        scaledSize: new google.maps.Size(30, 30),
      },
      map: this.map,
    });

    var destinationMarker = new google.maps.Marker({
      position: destination,
      title: "Workshop Location",
      icon: {
        url: "./assets/front/img/workshop.jpg",
        scaledSize: new google.maps.Size(30, 30),
      },
      map: this.map,
    });

    var waypointMarker = new google.maps.Marker({
      position: waypointt,
      title: "Vendor Location",
      icon: {
        url: "./assets/front/img/vendar-icon.png",
        scaledSize: new google.maps.Size(30, 30),
      },
      map: this.map,
    });

    let request: any = {
      origin: origin,
      destination: destination,
      waypoints: [{ location: waypointt }],
      travelMode: google.maps.TravelMode.DRIVING,
      // avoidTolls: true,
    };

    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        suppressMarkers: true,
        map: this.map,
        draggable: true,
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
        this.dr.setPanel(document.getElementById("directionsList"));

        var directionsData = response.routes[0].legs;

        if (this.vendorDistanceStatus) {
          this.vendorDistance = parseFloat(
            directionsData[0].distance.text
          ).toFixed(1);
          this.vendorTravelTime = this.formatDuration(
            directionsData[0].duration.value
          );

          console.log("vendorDistance: ", this.vendorDistance);
          console.log("vendorTravelTime: ", this.vendorTravelTime);

          if (directionsData.length > 1) {
            // Calculate and display distance and duration for vendor to workshop
            this.vendorShowroomDist = parseFloat(
              directionsData[1].distance.text
            ).toFixed(1);
            this.vendorShowroomTime = this.formatDuration(
              directionsData[1].duration.value
            );
            console.log("vendorShowroomDist: ", this.vendorShowroomDist);
            console.log("vendorShowroomTime: ", this.vendorShowroomTime);

            let staticCustomerLocation = {
              lat: Number(this.customerLat),
              lng: Number(this.customerLong),
            };

            let staticWorkshopLocation = {
              lat: Number(this.latlong?.workshop_location.lat),
              lng: Number(this.latlong?.workshop_location.longi),
            };
            console.log("staticCustomerLocation", staticCustomerLocation);
            console.log("staticWorkshopLocation", staticWorkshopLocation);
            var customerToWorkshopRequest: any = {
              origin: staticCustomerLocation,
              destination: staticWorkshopLocation,
              travelMode: google.maps.TravelMode.DRIVING,
              avoidTolls: true,
            };

            this.ds.route(
              customerToWorkshopRequest,
              (customerToWorkshopResponse, customerToWorkshopStatus) => {
                if (
                  customerToWorkshopStatus == google.maps.DirectionsStatus.OK
                ) {
                  var customerToWorkshopData =
                    customerToWorkshopResponse.routes[0].legs[0];
                  this.custShowroomDist = parseFloat(
                    customerToWorkshopData.distance.text
                  ).toFixed(1);
                  this.custtoShowroomTime = this.formatDuration(
                    customerToWorkshopData.duration.value
                  );
                  console.log("custShowroomDist", this.custShowroomDist);
                  console.log("custtoShowroomTime", this.custtoShowroomTime);
                  this.getVendorDistanceTime(); //for update distance and time
                }
              }
            );
          }
        }
      }
    });

    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.map);

    this.ds.route(request, (response, status) => {
      if (status == "OK") {
        this.dr.setDirections(response);
        var directionsData = response.routes[0].legs[0];
        if (!directionsData) {
          console.log("Directions request failed");
          return;
        } else {
          this.dr.setDirections(response);

          document.getElementById("msg").innerHTML +=
            " Driving distance is " +
            directionsData.distance.text +
            " (" +
            directionsData.duration.text +
            ").";
        }
      } else {
        console.log("Directions request failed due to " + status);
        return;
      }
    });
  }

  // mapInitializer(): void {
  //   this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
  // }
  mapInitializer() {
    const coordinates = new google.maps.LatLng(this.lat1, this.lng2);
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 15,
      scrollwheel: true,
      disableDefaultUI: true,
    };

    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer();

    this.dr.setMap(this.map);

    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    console.log("Map initialized:", this.map);
    // this.setRoutePolyline();
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.isImageCaptured = true;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info("received webcam image", webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  onSaveVendorDetails() {
    // console.log(payload);
    if (this.vendorDetailsForm.invalid) {
      Swal.fire("Please Enter All Filed!");
      return;
    }

    if (this.webcamImage == null) {
      Swal.fire("Please Set Your Image!");
      return;
    }

    let payload = {
      image: this.webcamImage?.imageAsDataUrl,
      vendor_lat: this.currentLat,
      vendor_long: this.currentLong,
      task_id: this.get_id,
      // vendor_id: this.vendor_id,
      vendor_name: this.vendorDetailsForm.value.vendor_name,
      vendor_number: this.vendorDetailsForm.value.vendor_number,
    };

    this.loaderActive = true;
    this.commonService.submitVendorLatLong(payload).subscribe((response) => {
      this.vendorInfo = response;
      this.vendor_latlong_status = this.vendorInfo;
      this.vendor_submit_details = this.vendorInfo;
      // console.log("Vendordetails",this.vendorInfo);
      this.loaderActive = false;
      if (this.vendor_latlong_status.status === true) {
        // console.log(this.vendorInfo);
        Swal.fire("Data saved");
        this.showVendorForm = false;
        this.directionList = true;
        this.showReachStatusButton = true;
        this.vendor_name =
          this.vendor_submit_details?.vendor_details?.member_Name;
        this.vendor_mob_no =
          this.vendor_submit_details?.vendor_details?.mobile_no;
        this.start_time = this.vendor_latlong_status?.result?.task_start_time;
        this.vendor_id = this.vendor_submit_details?.vendor_details?.id;
        this.vendor_lat = this.vendor_latlong_status?.vendor_lat;
        this.vendor_longi = this.vendor_latlong_status?.vendor_long;
        this.vendorDistanceStatus = true;
        this.setRoutePolyline();
      } else {
        Swal.fire(this.vendor_latlong_status.message);
        location.reload();
        // Swal.fire("Something went wrong");
      }
      // if (this.result.status) {
      //   //location.reload();
      //   // this.router.navigate(['/admin/customers']);
      // } else {
      //   this.errorMsg = this.result.message;
      // }

      // setTimeout(() => {
      //   this.successMsg = "";
      //   this.errorMsg = "";
      // }, 2000);
    });

    //}
  }
  onUpdateReachStatusDisplay() {
    this.popupTitle = "Update Reach Status";
    this.updateReachStatusDisplay = "block";
  }
  onUpdateReachStatus() {
    this.loaderActive = true;

    let currentDateTime = this.datePipe.transform(
      new Date(),
      "EEE MMM/dd/YYYY "
    );

    const { formattedTime, hour, minute, second, amPm } = this.getFormattedTimeComponents(this.updateReachStatusForm.value.reach_time);

    console.log(formattedTime);

    let payload = {
      task_id: this.get_id,
      vendor_id: this.vendor_id,
      schedule_time:
          currentDateTime +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second +
          " " +
          amPm,
      comment: this.updateReachStatusForm.value.comments,
    };

    

    // let payload = {
    //   task_id: this.get_id,
    //   vendor_id: this.vendor_id,
    //   schedule_time:
    //     this.datePipe.transform(this.currentDate, "dd-MM-yyyy") +
    //     " " +
    //     this.updateReachStatusForm.value.reach_time.hour +
    //     ":" +
    //     this.updateReachStatusForm.value.reach_time.minute,
    //   comment: this.updateReachStatusForm.value.comments,
    // };
    // console.log("payload", payload);

    this.commonService
      .updateVendorReachStatus(payload)
      .subscribe((response: any) => {
        this.reachtime_result = response;
        this.loaderActive = false;
        if (this.reachtime_result.status == true) {
          Swal.fire("Vendor Reached");
          this.updateReachStatusDisplay = "none";
          this.reached_time = this.reachtime_result.result.reach_time;
          this.reachedStatus = this.reachtime_result.result.reach_status;
          // this.start_time=this.reachtime_result.result.task_start_time;
          // this.vendor_mob_no=this.reachtime_result.result.vendor_contact_no;
          console.log(this.reachtime_result.result.reach_status);
          this.showVendorForm = false;
          this.showReachStatusButton = false;
          this.updateStatusButton = true;
          // this code Comment By Video Upload
          // this.updateStatusButton = false;
          // this.updateVideoButton = true;
          this.showMap = false;
        } else {
          Swal.fire(this.reachtime_result.message);
          this.updateReachStatusDisplay = "none";
          location.reload();
        }
        //  this.getpolicydata();
        //  this.reachtime=this.result.reach_time;
        // if (this.reachtime_result.status) {
        //   this.successMsg = this.reachtime_result.message;
        //   location.reload();
        // } else {
        //   this.errorMsg = this.reachtime_result.message;
        // }
      });
  }

  onUpdateTaskStatusDisplay() {
    this.popupTitle = "Update Task Status";
    this.updateTaskStatusDisplay = "block";
  }

  onUploadVideoDisplay() {
    this.uploadVideoStatusDisplay = "block";
  }

  onUploadVideoDisplay1() {
    this.uploadVideoStatusDisplay = "none";
  }

  onUploadDroppVideoDisplay() {
    this.uploadDroppVideoStatusDisplay = "block";
  }

  onUploadDroppVideoDisplay1() {
    this.uploadDroppVideoStatusDisplay = "none";
  }

  onUpdateTaskStatus() {
    this.loaderActive = true;
    let sendData = {
      task_id: this.get_id,
      comment: this.updateTaskStatusForm.value.comments,
      status: this.updateTaskStatusForm.value.status,
      vendor_id: this.vendor_id,
    };
    console.log("Rahul sendData", sendData);
    // return;
    this.commonService
      .updateVendorTaskStatus(sendData)
      .subscribe((response) => {
        this.status_result = response;
        this.loaderActive = false;
        if (this.status_result.status == true) {
          this.successMsg = this.status_result.message;
          Swal.fire("Status Updated");
          this.updateTaskStatusDisplay = "none";
          this.updateStatusButton = false;
          this.updateVideoButton = false;
          location.reload();
        } else {
          this.errorMsg = this.status_result.message;
          Swal.fire({ icon: "error", title: this.errorMsg });
        }
      });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadVideoTaskStatusForm.patchValue({
        uploadVideo: file
      });
    }
  }

  onUploadVideoForVendor() {
    if (this.uploadVideoTaskStatusForm.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append('task_id', this.get_id);
    formData.append('vendor_id', this.vendor_id);
    formData.append('video_status', 'pick');
    formData.append('uploadVideo', this.uploadVideoTaskStatusForm.get('uploadVideo').value);
    this.loaderActive = true;
    this.commonService.uploadVideoByVendor(formData).subscribe(response => {
      this.loaderActive = false;
      this.status_result = response;
      if (this.status_result.status == true) {
        Swal.fire("Uploaded successfully");
        // Update the UI as needed
        this.uploadVideoStatusDisplay = "none";
        this.updateVideoButton = false;
        location.reload();
      } else {
        this.errorMsg = this.status_result.message;
        Swal.fire({ icon: "error", title: this.errorMsg });
      }
    }, error => {
      this.loaderActive = false;
      this.errorMsg = 'An error occurred';
      Swal.fire({ icon: "error", title: this.errorMsg });
    });
  }

  onDroppFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadDroppingVideoForm.patchValue({
        uploadDroopCustomerVideo: file
      });
    }
  }

  onUploadDroppingVideoForVendor () {
    if (this.uploadDroppingVideoForm.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append('task_id', this.get_id);
    formData.append('vendor_id', this.vendor_id);
    formData.append('video_status', 'drop');
    formData.append('uploadVideo', this.uploadDroppingVideoForm.get('uploadDroopCustomerVideo').value);
    this.loaderActive = true;
    this.commonService.uploadVideoByVendor(formData).subscribe(response => {
      this.loaderActive = false;
      this.status_result = response;
      if (this.status_result.status == true) {
        Swal.fire("Uploaded successfully");
        // Update the UI as needed
        this.uploadVideoStatusDisplay = "none";
        this.updateVideoButton = false;
        location.reload();
      } else {
        this.errorMsg = this.status_result.message;
        Swal.fire({ icon: "error", title: this.errorMsg });
      }
    }, error => {
      this.loaderActive = false;
      this.errorMsg = 'An error occurred';
      Swal.fire({ icon: "error", title: this.errorMsg });
    });
  }
 

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  getVendorDistanceTime() {
    // console.log(this.custVendorDeatails);
    let distanceTime = {
      task_id: this.get_id,
      vendortocustDistance: this.vendorDistance,
      vendortocustTime: this.vendorTravelTime,
      vendortoShowroomDistance: this.vendorShowroomDist,
      vendortoShowroomTime: this.vendorShowroomTime,
      custtoShowroomDistance: this.custShowroomDist,
      custtoShowroomTime: this.custtoShowroomTime,
    };
    console.log("distanceTime", distanceTime);

    this.commonService
      .getVendorLinkDistanceTime(distanceTime)
      .subscribe((response) => {
        this.details = response;
        // window.location.reload();
        //console.log(this.data);
      });
  }


  getFormattedTimeComponents(time: { hour: number, minute: number, second?: number }): { formattedTime: string, hour: string, minute: string, second: string, amPm: string } {
    const seconds = time.second !== undefined ? time.second : 0;
    let hour = time.hour;
    let amPm = 'AM';
  
    // Determine AM/PM and convert hour to 24-hour format
    if (hour >= 12) {
      amPm = 'PM';
      if (hour > 12) {
        hour -= 12; // Convert PM hours to 12-hour format for display
      }
    } else if (hour === 0) {
      hour = 12; // Midnight hour in 12-hour format
    }
  
    // For 24-hour format, hour should be unchanged
    const formattedHour24 = (time.hour % 24).toString().padStart(2, '0');
    const formattedMinute = time.minute.toString().padStart(2, '0');
    const formattedSecond = seconds.toString().padStart(2, '0');
    const formattedTime24 = `${formattedHour24}:${formattedMinute}:${formattedSecond}`;
  
    // Combine 24-hour formatted time with AM/PM for readability
    const formattedTimeWithAmPm = `${formattedHour24}:${formattedMinute}:${formattedSecond} ${amPm}`;
  
    return {
      formattedTime: formattedTimeWithAmPm,
      hour: formattedHour24,
      minute: formattedMinute,
      second: formattedSecond,
      amPm
    };
  }

  formatDuration(rawDuration: number): string {
    // Convert duration from seconds to "HH:mm:ss" format
    const hours = Math.floor(rawDuration / 3600);
    const minutes = Math.floor((rawDuration % 3600) / 60);
    const seconds = rawDuration % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }




}
