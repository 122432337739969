import { Component  } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { Router } from  '@angular/router';
import Swal from 'sweetalert2'
import { environment } from '../environments/environment';
import {WebcamImage} from 'ngx-webcam';
import { DOCUMENT,Location } from '@angular/common';
import { Inject } from '@angular/core';
import { AuthService } from './admin/services/auth.service';
import { IpService } from './admin/services/ip-service.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {

  serverIpUrl = environment.baseUrl+'check-ip'; // Update with your Laravel API URL
  sessionTimeoutInSeconds = environment.sessionTimeoutInSeconds;
  public webcamImage: WebcamImage = null;
  isUserLoggedin: any;
  isAdminLoggedin: any;
  expiration: any;
  date_now: any;

  userLogout = environment.userLogout;
  adminLogout = environment.adminLogout;

  ngOnInit() {
    this.initializeIpCheck();
    this.loadGoogleMapsScript();
  }


	handleImage(webcamImage: WebcamImage) {
		this.webcamImage = webcamImage;
	}

	 constructor(
    @Inject(DOCUMENT) private document: Document,
    private bnIdle: BnNgIdleService,
    private router: Router,
    private authService: AuthService,
    private ipService: IpService,
    private location: Location
  ) {}


  private initializeIpCheck(): void {
  	console.log('call here');
   	this.ipService.getClientIp().subscribe((ip) => {
      console.log('Client IP:', ip);
      this.ipService.checkIpAddress(ip).subscribe((allowed) => {
      	console.log('allowed',allowed);
       

        this.expiration = parseFloat(localStorage.getItem('sessionExpiration') || '0');
        this.isAdminLoggedin = sessionStorage.getItem('isAdminLoggedin');
        this.date_now = Date.now();
        
        const currentPath = this.location.path();

        if (!allowed && !currentPath.includes('vendor-location-details') && !currentPath.includes('customer-location-details') ) {
            this.router.navigate(['/not-allowed']);
        }

        if (this.authService.getToken()) {
          this.authService.startLogoutTimer();
        }
        
        const check_timestamp = this.expiration < this.date_now ? true : false ;
        console.log('check_timestamp',check_timestamp);
        console.log('isUserLoggedin',this.isUserLoggedin);
        
        if (this.expiration < this.date_now) {
          if (this.isUserLoggedin) {
            this.router.navigate([this.userLogout]);
          } else if (this.isAdminLoggedin) {
            this.router.navigate([this.adminLogout]);
          }
        }

        if (allowed && currentPath.includes('not-allowed')) {
          this.router.navigate(['/admin/login']);
        }

       
      });
    });
  }



	// constructor(
	// 	@Inject(DOCUMENT) private document: Document,
	// 	private bnIdle: BnNgIdleService,
	// 	private router: Router,
	// 	private authService: AuthService,
	// 	private ipService: IpService
	// 	){

	// 	  this.ipService.getClientIp().then((ip) => {
	//       console.log('Client IP:', ip);
	//       this.ipService.validateIpAddress(ip).subscribe((allowed) => {
	//         if (allowed) {
	// 		    this.expiration =parseFloat(localStorage.getItem('sessionExpiration'));
	// 			this.isAdminLoggedin = sessionStorage.getItem('isAdminLoggedin');
	// 			this.date_now = Date.now();

	// 			if (this.authService.getToken()) {
	// 	           this.authService.startLogoutTimer();
	// 	        }

	// 	        if (this.expiration < this.date_now) {
	// 		        	if(this.isUserLoggedin!='' && this.isUserLoggedin!=null && this.isUserLoggedin!=undefined){
	// 		            	this.router.navigate([this.userLogout]);
	// 		            }
	// 		            else
	// 		            if(this.isAdminLoggedin!='' && this.isAdminLoggedin!=null && this.isAdminLoggedin!=undefined){
	// 		            	this.router.navigate([this.adminLogout]);
	// 		            }
	// 		    }
	//         }else{
 //                console.log('Access Denied');
	//         }
	//       });

	//   }


    loadStyle(styleName: string) {
      const head = this.document.getElementsByTagName('head')[0];
      let themeLink = this.document.getElementById(
        'client-theme'
      ) as HTMLLinkElement;
      if (themeLink) {
        themeLink.href = styleName;
      } else {
        const style = this.document.createElement('link');
        style.id = 'client-theme';
        style.rel = 'stylesheet';
        style.href = `${styleName}`;
        head.appendChild(style);
      }
    }

  private loadGoogleMapsScript() {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }


}











