import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ResolveEnd, Router } from "@angular/router";
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbTimeStruct,
  NgbCalendar,
  NgbDatepicker,
  NgbTimepicker,
  NgbDatepickerConfig,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { CommonService } from "../services/common.service";
import { GoogleMapsLoaderService }  from '../services/google-maps-loader.service'
import { Subject, Observable, timer } from "rxjs";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { AfterViewInit, ElementRef } from "@angular/core";
import {} from "googlemaps";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-universallink',
  templateUrl: './universallink.component.html',
  styleUrls: ['./universallink.component.css']
})
export class UniversallinkComponent implements OnInit {

  baseUrlVideo = environment.baseUrl_video;
  center: google.maps.LatLngLiteral;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  start: number;
  end: number;
  loadTime: number;
  ds: google.maps.DirectionsService;
  dr: google.maps.DirectionsRenderer;
  directionsService: google.maps.DirectionsService;
  directionsDisplay: google.maps.DirectionsRenderer;

  mapElement: any;
  nextWebcam: any;
  task_id: any;
  loaderActive: boolean = false;
  costumer_location: any;
  jobidval: any;
  workshopLocation: any;
  get_task_id: any;
  vendor_assigned_status: any;
  latsource: any;
  submit: boolean;
  job_id: string;
  workshop_location: any;
  vendor_location: any;
  cust_location: any;
  workshop_status: any;
  vendorInfo: Object;
  vendor_status: any;
  latlong: any;
  reachtime: any;
  policyData: any;
  reachedStatus: any;
  reached_time: any;
  start_time: any;
  reachtime_result: any;
  options: { enableHighAccuracy: boolean; timeout: number; maximumAge: number };
  vendor_latlong_status: any;
  vendId: any;
  vendor_name: any;
  vendor_mob_no: any;
  showVendorForm: boolean = false;
  updateStatusButton: boolean = false;
  showReachStatusButton: boolean = false;
  vendor_submit_details: any;
  cust_name: any;
  cust_mob_no: any;
  cust_addr: any;
  cust_reg_no: any;
  reachStatus: any;

  showMap: boolean = true;

  updateVideoButton: boolean = false;
  droppVendorUploadForm: boolean = false;

  videoMsgShow: boolean = false;

  taskStatus: FormGroup;
  @ViewChild("mapContainer") gmap: ElementRef;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isTracking = false;
  map: google.maps.Map;
  lat1 = 19.1178548;
  lng2 = 72.8631304;

  // coordinates = new google.maps.LatLng(this.lat1, this.lng2);
  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   scrollwheel: true,
  //   disableDefaultUI: true,
  //   zoom: 15,
  // };

  title = "error";
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  directionList: boolean = true;
  public showWebcam = true;
  public allowCameraSwitch = true;
  //public switchCamera: Observable<boolean|string> = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  userDetailsForm: any;
  result: any;
  result_assign: any;
  errorMsg: any;
  successMsg: any;
  customer_location: any;
  vendorDetails: any;
  isImageTrue: boolean = false;
  vendor_info: boolean = false;
  showWebcamera: boolean = true;
  webcamImageEncoded: any;
  policy_details: any;
  display: any = "none";
  resultVendor: any;
  status_result: any;
  submitted: any;
  popupTitle: any;
  workshop_details: any;
  vendor_id: any;
  vendor_assign_id: any;
  vendor_details: any;
  customer_recent_no: any;
  idwatch: any;
  loadingactive: boolean = false;
  progress: any;
  updateReachStatusDisplay: any = "none";
  updateReachStatusForm: FormGroup;
  updateTaskStatusDisplay: any = "none";
  updateTaskStatusForm: FormGroup;
  vendorDetailsForm: FormGroup;
  uploadVideoTaskStatusForm: FormGroup;
  reach_time: any;
  uploadVideoStatusDisplay: any = "none";

  uploadDroppingVideoForm: FormGroup;
  uploadDroppVideoStatusDisplay: any = "none";

  currentDate = new Date();
  "MM/dd/yyyy h:mm:ss";
  taskId: any;
  scope: any;

  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  currentLat: any;
  currentLong: any;
  ids: any;
  row: any;
  showVendorAndCustomerDetails: boolean = false;
  isImageCaptured: boolean = false;
  get_id: any;
  pageExpire: boolean = false;
  customerLat: any;
  customerLong: any;
  intervalId: any;
  vendor_lat: any;
  vendor_longi: any;

  vendorDistance: any;
  vendorTravelTime: any;

  custShowroomDist: any;
  custtoShowroomTime: any;

  vendorShowroomDist: any;
  vendorShowroomTime: any;
  vendorDistanceStatus: boolean = false;
  meridian = true;
  details: any;

  viewPickUpVideoUrl: string = '';
  viewDropVideoUrl: string = '';

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private datePipe: DatePipe,
    private googleMapsLoader: GoogleMapsLoaderService
  ) {
    let currentDate = this.datePipe.transform(new Date(), "MM/dd/yyyy h:mm:ss");
  }

  ngOnInit(): void {
    this.task_id = this.activatedRoute.snapshot.paramMap.get("id");
    console.log(this.activatedRoute.snapshot.paramMap);
    this.get_id = atob(this.task_id);
    // console.log(this.get_id);
    this.vendorDetailsForm = this.formBuilder.group({
      image: [""],
      vendor_lat: this.currentLat,
      vendor_long: this.currentLong,
      vendor_id: [""],
      vendor_name: ["", [Validators.required]],
      // vendor_number: [
      //   "",
      //   [
      //     Validators.required,
      //     Validators.minLength(10),
      //     Validators.maxLength(10),
      //     // Validators.pattern('^(?:(?:\+91)|(?:91))?[789]\d{9}$'),
      //     Validators.pattern(/^[6-9]\d{9}$/),
      //   ],
      // ],
    });
    this.updateReachStatusForm = this.formBuilder.group({
      task_id: [""],
      reach_time: ["", [Validators.required]],
      comments: ["", [Validators.required]],
    });
    this.updateTaskStatusForm = this.formBuilder.group({
      taskId: [""],
      comments: ["", [Validators.required]],
      status: ["", [Validators.required]],
    });

    this.uploadVideoTaskStatusForm = this.formBuilder.group({
      taskId: [""],
      vendorId: [""],
      uploadVideo: ["", [Validators.required]],
    });

    this.uploadDroppingVideoForm = this.formBuilder.group({
      taskId: [""],
      vendorId: [""],
      uploadDroopCustomerVideo: ["", [Validators.required]],
    });
    
    this.loadTime = Date.now();
    timer(150000).subscribe(() => {
      // Map has expired, handle it here
      this.pageExpire == true;
      console.log("Map expired");
    });
    this.getpolicydata();
    this.getWorkshopDetails();
  }


  getWorkshopDetails() {
    var get_id = atob(this.task_id);
    // this.loaderActive = true;
    // console.log(get_id);
    let payload = {
      task_id: get_id,
      vendor_id: this.vendor_id,
    };

    this.commonService
      .getLatLongDetailsByWorkshop(payload)
      .subscribe((response: any) => {
        this.result = response;
        this.loaderActive = false;
        localStorage.setItem("result", JSON.stringify(this.result.result));

        if (this.result.workshop_status) {
          this.costumer_location = this.result.result.customer_location;
          this.jobidval = this.result.result.customer_location.jobid;
          this.vendorDetails = this.result.result.vendor_location;
          this.workshopLocation = this.result.result.workshop_location;
          this.task_id = this.get_task_id;
          //this.customer_name = this.customer_location.customer_name;
          this.vendor_assigned_status = this.result.vendor_asigned_status;
          if (this.vendor_assigned_status == true) {
            var waypoint = {
              latitude: this.currentLat,
              longitude: this.currentLong,
            };
            // console.log("custumer_location", waypoint);
            var origin = {
              latitude: this.vendorDetails[1]["lat"],
              longitude: this.vendorDetails[1]["longi"],
            };
            // console.log("vendorlocation", origin);
            var destination = {
              latitude: this.workshopLocation.lat,
              longitude: this.workshopLocation.longi,
            };
            // console.log("workshoplocation", destination);
          }
        }
        this.vendor_status = response.vendor_status;
        // console.log(this.vendor_status);

        let result = response.result;
        // console.log(result);

        this.vendor_details = result.vendor_details;
        this.workshop_details = result.workshop_location;
        // console.log("workshop",this.workshop_details);
        this.resultVendor = result.vendor_location;
        this.policy_details = result.customer_location;
      });
  }

  
  getpolicydata() {
    this.loaderActive = true;

    console.log(this.task_id);
    
    var get_id = atob(this.task_id);

    let SendData = {
      task_id: get_id,
    };
    this.commonService.getPolicyDataByTask(SendData).subscribe((response) => {
      this.result = response;
      // console.log(this.result);
      this.cust_name = this.result.customer_name;
      this.cust_mob_no = this.result.customer_mobile_no;
      this.cust_addr = this.result.customer_addr;
      this.cust_reg_no = this.result.customer_regitration_no;

      this.loaderActive = false;
      this.vendor_assign_id = this.result.vendor_id;

      if (this.result.status == true) {
        this.vendor_id = this.result.vendor_id;
        // console.log('Vendor Id',this.vendor_id);
        // this.reachedStatus = this.policyData.vendor_id;
        this.reached_time = this.result.reach_time;
        this.start_time = this.result.task_start_time;
        this.reachStatus = this.result.reach_status;
        this.customerLat = this.result.customer_lat;
        this.customerLong = this.result.customer_long;
        this.vendor_lat = this.result.vendor_lat;
        this.vendor_longi = this.result.vendor_longi;
        // console.log(this.reachStatus);

        this.vendor_mob_no = this.result.vendor_mobile_no;
        this.vendor_name = this.result.vendor_name;

        const vendor_distance = this.result.vendor_distance;
   
        if (this.result.pickupVideourl == '' && this.result.vendor_id > 0) {
          this.updateVideoButton = true;
        }

        if (this.result.pickupVideourl != '') {
          this.viewPickUpVideoUrl = this.baseUrlVideo + this.result.pickupVideourl;
        }
    
        if (this.result.dropVideourl != '') {
          this.viewDropVideoUrl = this.baseUrlVideo + this.result.dropVideourl;
        }


        if (this.result.dropVideourl == '' && this.result.vendor_id > 0) {
          this.droppVendorUploadForm = true;
        }

        if (this.result.vendor_id < 0 || this.result.vendor_id == '' ) {
          this.videoMsgShow = true;
        }
        

      } else {
        this.showVendorForm = true;
      }

      //this.getTemporaryTrack();
    });
  }

  closePopup1() {
    this.updateTaskStatusDisplay = "none";
  }
  closePopup() {
    this.updateReachStatusDisplay = "none";
  }

 


  









  onUpdateTaskStatusDisplay() {
    this.popupTitle = "Update Task Status";
    this.updateTaskStatusDisplay = "block";
  }

  onUploadVideoDisplay() {
    this.uploadVideoStatusDisplay = "block";
  }

  onUploadVideoDisplay1() {
    this.uploadVideoStatusDisplay = "none";
  }

  onUploadDroppVideoDisplay() {
    this.uploadDroppVideoStatusDisplay = "block";
  }

  onUploadDroppVideoDisplay1() {
    this.uploadDroppVideoStatusDisplay = "none";
  }


  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadVideoTaskStatusForm.patchValue({
        uploadVideo: file
      });
    }
  }

  onUploadVideoForVendor() {
    if (this.uploadVideoTaskStatusForm.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append('task_id', this.get_id);
    formData.append('vendor_id', this.vendor_id);
    formData.append('video_status', 'pick');
    formData.append('uploadVideo', this.uploadVideoTaskStatusForm.get('uploadVideo').value);
    this.loaderActive = true;
    this.commonService.uploadVideoByVendor(formData).subscribe(response => {
      this.loaderActive = false;
      this.status_result = response;
      if (this.status_result.status == true) {
        Swal.fire("Uploaded successfully");
        // Update the UI as needed
        this.uploadVideoStatusDisplay = "none";
        this.updateVideoButton = false;
        location.reload();
      } else {
        this.errorMsg = this.status_result.message;
        Swal.fire({ icon: "error", title: this.errorMsg });
      }
    }, error => {
      this.loaderActive = false;
      this.errorMsg = 'An error occurred';
      Swal.fire({ icon: "error", title: this.errorMsg });
    });
  }

  onDroppFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadDroppingVideoForm.patchValue({
        uploadDroopCustomerVideo: file
      });
    }
  }

  onUploadDroppingVideoForVendor () {
    if (this.uploadDroppingVideoForm.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append('task_id', this.get_id);
    formData.append('vendor_id', this.vendor_id);
    formData.append('video_status', 'drop');
    formData.append('uploadVideo', this.uploadDroppingVideoForm.get('uploadDroopCustomerVideo').value);
    this.loaderActive = true;
    this.commonService.uploadVideoByVendor(formData).subscribe(response => {
      this.loaderActive = false;
      this.status_result = response;
      if (this.status_result.status == true) {
        Swal.fire("Uploaded successfully");
        // Update the UI as needed
        this.uploadVideoStatusDisplay = "none";
        this.updateVideoButton = false;
        location.reload();
      } else {
        this.errorMsg = this.status_result.message;
        Swal.fire({ icon: "error", title: this.errorMsg });
      }
    }, error => {
      this.loaderActive = false;
      this.errorMsg = 'An error occurred';
      Swal.fire({ icon: "error", title: this.errorMsg });
    });
  }
 




  

 




}
