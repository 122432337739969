// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  encryptionKey:'83230975909b5d49561be1bb59c5e95f',
  encryptionIVKey:'fq5W1PgtauSYYTbZ',
  sessionExpiredSeconds:3600000,
  googleMapsApiKey: "AIzaSyBf4dk4gKMYMQSm1ukYGK1M5GTZDphfG3I",
 
  
  
  // ----Begin  LOCAL ----
  // production: false,
  // baseUrl: 'http://127.0.0.1:9011/api/',
  // baseUrl_angular: 'http://localhost:5120/',
  // mainJsPath: '/assets/front/js/main.js',
  // CaptchaSiteKey: '6LcERLcZAAAAAGl6z-ABhYOpJ3Mm7Kewp541OMYw',
  // minPasswordLength: 8,
  // maxPasswordLength: 12,
  // permissionDeniedMsg: 'You do not have privilege for this page.',
  // sessionTimeoutInSeconds: 3600,
  // razorpayKey: 'rzp_test_GbZxIg33hR1fNc',

  // userLogout:'/admin/logout',
  // adminLogout:'/admin/logout',

  // TodayCase:'/#/admin/today-cases',
  // AllCases:'/#/admin/allclaimdetails',
  // OpenCases:'/#/admin/opencases',
  // CustomerOnboarded:'/#/admin/customeronboarded',
  // VendorAssigned:'/#/admin/vendorassigned',
  // AssistanceScheduled:'/#/admin/assistancescheduled',
  // VendorDeclined:'/#/admin/vendordeclined',
  // TaskCompleted:'/#/admin/taskcompleted',
  // HoldCases:'/#/admin/hold-cases',
  
  // ----End  LOCAL ----

  // ---- Begin Indicosmic Dev ----
  // production: true,
  // baseUrl: 'https://myhrmsnow.com/newcrm/api/api/',
  // baseUrl_angular: 'https://myhrmsnow.com/newcrm/',
  // apiurl: 'https://myhrmsnow.com/newcrm/api/api/',
  // mainJsPath: '/TVS/assets/front/js/main.js',
  // CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
  // minPasswordLength: 8,
  // maxPasswordLength: 12,
  // permissionDeniedMsg: 'You do not have privilege for this page.',
  // sessionTimeoutInSeconds: 3600,

  // userLogout:'/admin/logout',
  // adminLogout:'/admin/logout',

  // TodayCase:'/newcrm/admin/today-cases',
  // AllCases:'/newcrm/admin/allclaimdetails',
  // OpenCases:'/newcrm/admin/opencases',
  // CustomerOnboarded:'/newcrm/admin/customeronboarded',
  // VendorAssigned:'/newcrm/admin/vendorassigned',
  // AssistanceScheduled:'/newcrm/admin/assistancescheduled',
  // VendorDeclined:'/newcrm/admin/vendordeclined',
  // TaskCompleted:'/newcrm/admin/taskcompleted',
  // HoldCases:'/newcrm/admin/hold-cases',
  // ---- End Indicosmic Uat ----

  //---- Begin Tvs Dev ----

  // production: true,
  // baseUrl: 'https://tvsmazcrmserviceappdev02.azurewebsites.net/api/',
  // baseUrl_angular: 'https://tvsmazcrmserviceappdev01.azurewebsites.net/#/',
  // apiurl: 'https://tvsmazcrmserviceappdev02.azurewebsites.net/api/',
  // mainJsPath: '/TVS/assets/front/js/main.js',
  // CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
  // minPasswordLength: 8,
  // maxPasswordLength: 12,
  // permissionDeniedMsg: 'You do not have privilege for this page.',
  // sessionTimeoutInSeconds: 3600,

  // userLogout:'/logout',
  // adminLogout:'/admin/logout',

  // TodayCase:'/#/admin/today-cases',
  // AllCases:'/#/admin/allclaimdetails',
  // OpenCases:'/#/admin/opencases',
  // CustomerOnboarded:'/#/admin/customeronboarded',
  // VendorAssigned:'/#/admin/vendorassigned',
  // AssistanceScheduled:'/#/admin/assistancescheduled',
  // VendorDeclined:'/#/admin/vendordeclined',
  // TaskCompleted:'/#/admin/taskcompleted',
  // HoldCases:'/#/admin/hold-cases',

  //---- End Tvs Dev ----

  // ---- Begin Tvs UAT ----

  // production: true,
  // baseUrl: 'https://tvsmazcrmserviceappdev02.azurewebsites.net/api/',
  // baseUrl_angular: 'https://tvsmazcrmserviceappuat01.azurewebsites.net//#/',
  // apiurl: 'https://tvsmazcrmserviceappdev02.azurewebsites.net/api/',
  // mainJsPath: '/TVS/assets/front/js/main.js',
  // CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
  // minPasswordLength: 8,
  // maxPasswordLength: 12,
  // permissionDeniedMsg: 'You do not have privilege for this page.',
  // sessionTimeoutInSeconds: 3600,

  // userLogout:'/logout',
  // adminLogout:'/admin/logout',

  // TodayCase:'/#/admin/today-cases',
  // AllCases:'/#/admin/allclaimdetails',
  // OpenCases:'/#/admin/opencases',
  // CustomerOnboarded:'/#/admin/customeronboarded',
  // VendorAssigned:'/#/admin/vendorassigned',
  // AssistanceScheduled:'/#/admin/assistancescheduled',
  // VendorDeclined:'/#/admin/vendordeclined',
  // TaskCompleted:'/#/admin/taskcompleted',
  // HoldCases:'/#/admin/hold-cases',

  //---- End Tvs Dev ----
  // ---- Begin Tvs Dev ----

  // production: true,
  // baseUrl: 'https://dev-crm.tvsservice.com/api/public/',
  // baseUrl_angular: 'https://dev-crm.tvsservice.com/#/',
  // apiurl: 'https://dev-crm.tvsservice.com/api/public/',
  // mainJsPath: '/TVS/assets/front/js/main.js',
  // CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
  // minPasswordLength: 8,
  // maxPasswordLength: 12,
  // permissionDeniedMsg: 'You do not have privilege for this page.',
  // sessionTimeoutInSeconds: 3600,

  // userLogout:'/logout',
  // adminLogout:'/admin/logout',

  // TodayCase:'/#/admin/today-cases',
  // AllCases:'/#/admin/allclaimdetails',
  // OpenCases:'/#/admin/opencases',
  // CustomerOnboarded:'/#/admin/customeronboarded',
  // VendorAssigned:'/#/admin/vendorassigned',
  // AssistanceScheduled:'/#/admin/assistancescheduled',
  // VendorDeclined:'/#/admin/vendordeclined',
  // TaskCompleted:'/#/admin/taskcompleted',
  // HoldCases:'/#/admin/hold-cases',

  //---- End Tvs Dev ----

  // ---- Begin Tvs Live ----

  production: true,
  baseUrl: 'https://crm-api.tvsservice.com/api/',
  baseUrl_angular: 'https://crm.tvsservice.com/#/',
  apiurl: 'https://crm-api.tvsservice.com/api/',
  mainJsPath: '/TVS/assets/front/js/main.js',
  CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
  minPasswordLength: 8,
  maxPasswordLength: 12,
  permissionDeniedMsg: 'You do not have privilege for this page.',
  sessionTimeoutInSeconds: 3600,

  userLogout:'/admin/logout',
  adminLogout:'/admin/logout',

  TodayCase:'/#/admin/today-cases',
  AllCases:'/#/admin/allclaimdetails',
  OpenCases:'/#/admin/opencases',
  CustomerOnboarded:'/#/admin/customeronboarded',
  VendorAssigned:'/#/admin/vendorassigned',
  AssistanceScheduled:'/#/admin/assistancescheduled',
  VendorDeclined:'/#/admin/vendordeclined',
  TaskCompleted:'/#/admin/taskcompleted',
  HoldCases:'/#/admin/hold-cases',

  //---- End Tvs Live ----
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
