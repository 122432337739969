import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: 'root',
})
export class IpService {
  private laravelApiUrl = environment.baseUrl+'check-ip' // Update with your Laravel API URL
  private serverIpUrl = environment.baseUrl+'getClientIp' // Update with your Laravel API URL for getting IP

  constructor(private http: HttpClient) {}

  // Method to check if the provided IP is allowed
  checkIpAddress(ip: string): Observable<boolean> {
    return this.http.post<{ allowed: boolean }>(this.laravelApiUrl, { ip }).pipe(
      map(response => response.allowed),
      catchError((error) => {
        console.error('Error checking IP:', error);
        return of(false); // Default to false on error
      })
    );
  }

  // Method to get the client's IP address from the server
  getClientIp(): Observable<string> {
    return this.http.get<{ ip: string }>(this.serverIpUrl).pipe(
      map(response => response.ip),
      catchError((error) => {
        console.error('Error retrieving IP address from server:', error);
        return of('0.0.0.0'); // Default to '0.0.0.0' on error
      })
    );
  }
}
