
<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-12">
                <h3 class="page-title">TVSMotor RSA Report</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
             <div class="card shadowbox">
                <div class="card-body pb-0">
                    <div class="table-responsive">
                        <!-- <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover table table-sm table-bordered my-dataTable"> -->
                            <table datatable  class="row-border hover table table-sm table-bordered my-dataTable">
                          <thead class="thead-light">
                             <tr>
                              <th>Id</th>
                              <th>Ticket No</th>
                              <th>Frame No</th>
                              <th>Ticket Reg Date</th>
                              <th>Customer Name</th>
                              <th>Customer Mobile No</th>
                              <th>Customer City</th>
                              <th>Customer State</th>
                              <th>Model</th>
                              <th>Registration No</th>
                              <th>Policy purchase Date</th>
                              <th>Warranty Status</th>
                              <th>Breakdown location</th>
                              <th>Case Reg Date</th>
                              <th>Case Reg Time</th>
                              <th>Vendor Assign Time</th>
                              <th>Time Taken to Assign</th>
                              <th>Reach Time</th>
                              <th>Time Taken to Reach</th>
                              <th>Reach Time Slab</th>
                              <th>SLA Status</th>
                              <th>Delay Reason</th>
                              <th>Cancelled Time</th>
                              <th>Time To Cancel</th>
                              <th>Cancel Reason</th>
                              <th>Mechanical ASP Name</th>
                              <th>Mech ASP Code</th>
                              <th>Mech Km</th>
                              <th>Mech ASP Dist</th>
                              <th>Towing ASP Name</th>
                              <th>Towing ASP Code</th>
                              <th>Towing ASP District</th>
                           </tr>
                          </thead>
                          <!-- <tbody *ngIf="result?.length != 0">
                             <tr *ngFor="let row of result; let i = index" >
                            <td *ngIf="row.question_id!=null" >{{ i+1 }}</td>
                            <td *ngIf="row.question_id!=null"> TVSM1819001167 </td>
                            <td *ngIf="row.question_id!=null" >{{ row.frame_no }}</td>
                            <td *ngIf="row.question_id!=null" >{{ row.created_at }}</td>
                            <td *ngIf="row.question_id!=null" >{{ row.customer_name }}</td>
                            <td *ngIf="row.question_id!=null" >{{ row.customer_mobile_no }}</td>
                            <td *ngIf="row.question_id!=null" >{{ row.cust_city_name }}</td>
                            <td *ngIf="row.question_id!=null" >{{ row.cust_state }}</td>
                            <td *ngIf="row.question_id!=null">{{ row.model_name }}</td>
                            <td *ngIf="row.question_id!=null" >{{ row.registration_no }}</td>
                            <td *ngIf="row.question_id!=null">{{ row.sold_policy_date }}</td>
                            <td *ngIf="row.question_id!=null" >{{  row.warranty_status }}</td>
                            <td *ngIf="row.question_id!=null"  >{{  row.cust_curr_addr }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.case_reg_date }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.case_reg_time }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.vendor_assign_time }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.time_take_vendor_assign }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.vendor_reached_time }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.time_takeToReach_vendor }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.reach_time_slab }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.sla_status }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.delay_reason }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.cancel_time }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.cancel_reason }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.time_takeTo_cancel }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.mech_asp_name }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.mech_asp_code }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.mech_km }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.mech_asp_district }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.tow_asp_name }}</td>
                            <td *ngIf="row.question_id!=null">{{  row.tow_asp_code }}</td> 
                            <td *ngIf="row.question_id!=null">{{  row.tow_asp_district }}</td>
                             </tr>
                          </tbody> -->
                        </table>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
          
            <div class="card shadowbox">
               
            </div>
        </div>
    </div>
</div>

