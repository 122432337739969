import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { APP_BASE_HREF, Location } from "@angular/common";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
//import { FrontInterceptor } from './front/share/front.interceptor';
import { AdminInterceptor } from "./admin/share/admin.interceptor";

import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SimpleNotificationsModule } from "angular2-notifications";

import { BnNgIdleService } from "bn-ng-idle";
import { ChartsModule } from "ng2-charts";
import { DatePipe } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";

import { CommonModule } from "@angular/common";
import { VendorlocationComponent } from "./admin/vendorlocation/vendorlocation.component";
import { CustomerLocationDetailsComponent } from "./admin/customer-location-details/customer-location-details.component";
import { WebcamModule } from "ngx-webcam";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoaderComponent } from "./loader/loader.component";
import { AuthService } from './admin/services/auth.service';
import { TrimSpacesDirective } from './admin/shared/directives/trim-spaces.directive';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';

import { UniversallinkComponent } from "./admin/universallink/universallink.component";


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoaderComponent,
    AccessDeniedComponent,
    DashboardComponent,
    VendorlocationComponent,
    CustomerLocationDetailsComponent,
    TrimSpacesDirective,
    NotAllowedComponent,
    UniversallinkComponent
  ],
  imports: [
    CommonModule, 
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot(),
    WebcamModule,
    NgbModule,
  ],
  providers: [

    AuthService,
    //{ provide: HTTP_INTERCEPTORS, useClass: FrontInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true },
    // { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
    // { provide: APP_BASE_HREF, useValue: "/newcrm/" || "/" },
    {provide : LocationStrategy , useClass: HashLocationStrategy},

    BnNgIdleService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
