import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private logoutTimer: any;
  userLogout = environment.userLogout;
  adminLogout = environment.adminLogout;
  private readonly logoutTime: number = environment.sessionExpiredSeconds;

  constructor(private router: Router) {}

  login(token: string) {
    sessionStorage.setItem('token', token);
    this.startLogoutTimer();
  }

  logout() {
    console.log('logout');
    sessionStorage.clear();
    this.router.navigate([this.adminLogout]);
    clearTimeout(this.logoutTimer);
  }

  startLogoutTimer() {
    this.logoutTimer = setTimeout(() => {
      this.logout();
    }, this.logoutTime); 
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }
}
